// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("turbolinks").start()
require("app")

import "bootstrap"
import "../stylesheets/application" 

document.addEventListener("turbolinks:load", () => {
	$('[data-toggle="tooltip"]').tooltip()
	$('[data-toggle="popover"]').popover()
  function delay(callback, ms) {
    var timer = 0;
    return function() {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  $('#bloodhound .typeahead').keyup(delay(function (e) {
    $.getJSON(window.location.origin+"/search/q/q", { 'q': this.value } )
      .done(function( data ) {
        $("#search_list").html('');
        $("#search_list").append(data.data)
      });
  }, 50));  

  $("body").click(function(event){
    if($(event.target).hasClass('typeahead')){
      return false 
    }

    if( !$("#search_list").is(':empty') && !$("#search_list").hasClass('d-none') ) {
      $("#search_list").addClass('d-none')
    }
  })
  $("body").on("click", '#bloodhound .typeahead', function(){
    $("#search_list").removeClass()
  })
})



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
