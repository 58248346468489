document.addEventListener("turbolinks:load", () => {
  // $("li.dropdown-toggle").mouseover(function() {
  //   $(this).dropdown('toggle');
  // });

  // $("li.dropdown-toggle").mouseout(function() {
  //   $(this).dropdown('toggle');
  // }); 

  // $("li.dropdown-toggle a").click(function() {
  //   console.log($(this).attr('href'));
  //   window.location.href = $(this).attr('href')
  // })

})